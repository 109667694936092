import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Center,
  Button,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import { LocalStorage } from 'utils/local_stoage';
import { STAFF_TOKEN_LOCAL_KEY } from 'config/const';
import { useToast } from 'utils/toast';
import { Fetch } from 'utils/fetch';
import { Index as Response } from 'interfaces/api/login/index';

const validationSchemas = yup.object({
  account: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('メールアドレスを入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードを正しく入力してください')
    .max(20, 'パスワードを正しく入力してください')
});

export const Index = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const { sendToast } = useToast();

  const formik = useFormik({
    initialValues: {
      account: '',
      password: '',
    },
    validationSchema: validationSchemas,
    onSubmit: (values: any) => {
      Fetch.post('login', values).then(res => {
        if (res?.error) {
          // setFormLoading(false);
          sendToast({title: 'ログイン失敗しました', description: 'メールアドレスもしくはパスワードが違います。', status: 'error'});
          return false;
        }

        if (!res.token || res.token === '') {
          sendToast({title: 'ログイン失敗しました', description: 'メールアドレスもしくはパスワードが違います。', status: 'error'});
          return false;
        }

        LocalStorage.set(STAFF_TOKEN_LOCAL_KEY, res.token);
        navigate(`/`);
        return false;
      });
    },
  });

  return (
    <Center
      w='full'
      h='100vh'
      sx={{
        bgImage: '/static/images/login/bg.jpg',
        bgSize: 'cover',
        opacity: 0.9
      }}
    >
      <Box
        bg='white'
        minW={300}
        maxW={500}
        w='80%'
        sx={{ opacity: 0.8 }}
        p={5}
      >
        <Center
          mt={3}
          mb={8}
        >
          <Image src='/static/images/logo/200x200.jpeg' alt='logo' w={60} />
        </Center>

        <form onSubmit={formik.handleSubmit} noValidate>
          <FormControl
            mt={5}
            isRequired
            isInvalid={formik.touched.account && Boolean(formik.errors.account)}
          >
            <FormLabel htmlFor='account'>メールアドレス</FormLabel>
            <Input
              id='account'
              name='account'
              type='email'
              placeholder='メールアドレスを入力してください'
              isRequired
              onChange={formik.handleChange}
              value={formik.values.account}
              maxLength={100}
            />
            <FormErrorMessage>{String(formik.errors.account)}</FormErrorMessage>
          </FormControl>

          <FormControl
            mt={5}
            isRequired
            isInvalid={formik.touched.password && Boolean(formik.errors.password)}
          >
            <FormLabel htmlFor='password'>パスワード</FormLabel>
            <Input
              id='password'
              name='password'
              type='password'
              placeholder='パスワードを入力してください'
              isRequired
              onChange={formik.handleChange}
              value={formik.values.password}
              maxLength={20}
            />
            <FormErrorMessage>{String(formik.errors.password)}</FormErrorMessage>
          </FormControl>

          <Button
            type='submit'
            my={5}
            w='full'
            colorScheme='base'
          >
            ログイン
          </Button>

        </form>
      </Box>
    </Center>
  );
};
