import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Select,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/my/index';

const step1ValidationSchemas = yup.object({
  id: yup
    .number()
    .required('会員IDを入力してください')
    .min(100000, '会員IDを正しく入力してください')
    .max(9999999999, '会員IDを正しく入力してください')
});

const step2ValidationSchemas = yup.object({
  name: yup
    .string()
    .required('お名前を入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードを正しく入力してください')
    .max(20, 'パスワードを正しく入力してください'),
  passwordConfirm: yup
    .string()
    .required('確認用パスワードを入力してください')
    .min(8, 'パスワードを正しく入力してください')
    .max(20, 'パスワードを正しく入力してください')
    .oneOf([yup.ref('password')], 'パスワードが一致しません'),
  approvalPeriod: yup
    .string()
    .required('認定有効期限を入力してください'),
  approvalAt: yup
    .string()
    .required('認定日を入力してください'),
});

export const Create = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [step, setStep] = React.useState<number>(1);
  const { sendToast } = useToast();

  const step1Form = useFormik({
    initialValues: {
      id: undefined,
    },
    validationSchema: step1ValidationSchemas,
    onSubmit: (values: any) => {
      // メールアドレスの重複チェック
      Fetch.get(`members/validate?id=${values.id}`).then(res => {
        if (res?.error) {
          sendToast({title: '会員ID重複エラー', description: '入力された会員IDは既に使用されています。他の会員IDを入力してください。', status: 'error'});
          return false;
        }

        setStep(2);
      });
    },
  });

  const step2Form = useFormik({
    initialValues: {
      name: '',
      kana: '',
      password: '',
      passwordConfirm: '',
      approvalPeriod: undefined,
      approvalAt: undefined,
      tel: undefined,
      email: undefined,
      birth: undefined,
      memo: undefined,
      company: undefined,
      department: undefined,
      position: undefined,
      addressType: 1,
      zip: undefined,
      pref: undefined,
      address1: undefined,
      address2: undefined,
      address3: undefined,
    },
    validationSchema: step2ValidationSchemas,
    onSubmit: (values: any) => {
      values.id = step1Form.values.id;

      Fetch.post('members', values).then(res => {
        if (res?.error) {
          sendToast({title: '作成失敗しました', description: '', status: 'error'});
          return false;
        }

        navigate('/members');
        return false;
      });
    },
  });

  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, []);

  return (
    <Layout
      currentNavi='members'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >
        <Card
          w='full'
          h='full'
        >
          <form onSubmit={(step === 1) ? step1Form.handleSubmit : step2Form.handleSubmit} noValidate>
            <CardHeader>
              <Heading size='md'>会員新規作成</Heading>
            </CardHeader>
            <CardBody>
              <FormControl
                isRequired
                pb={3}
                isInvalid={step1Form.touched.id && Boolean(step1Form.errors.id)}
              >
                <FormLabel htmlFor='id'>会員ID</FormLabel>
                <Input
                  id='id'
                  name='id'
                  type='number'
                  size='lg'
                  placeholder='会員ID'
                  isRequired
                  onChange={step1Form.handleChange}
                  value={step1Form.values.id}
                  isDisabled={step === 2}
                />
                <FormErrorMessage>{String(step1Form.errors.id)}</FormErrorMessage>
              </FormControl>

              {step === 2 &&
                <>

                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >基本情報</Heading>
                <Divider />

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.name && Boolean(step2Form.errors.name)}
                >
                  <FormLabel htmlFor='name'>会員名</FormLabel>
                  <Input
                    id='name'
                    name='name'
                    type='text'
                    size='lg'
                    placeholder='会員名'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.name}
                    maxLength={20}
                  />
                  <FormErrorMessage>{String(step2Form.errors.name)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='name'>フリガナ</FormLabel>
                  <Input
                    id='kana'
                    name='kana'
                    type='text'
                    size='lg'
                    placeholder='フリガナ'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.kana}
                    maxLength={20}
                  />
                </FormControl>

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.password && Boolean(step2Form.errors.password)}
                >
                  <FormLabel htmlFor='password'>パスワード</FormLabel>
                  <Input
                    id='password'
                    name='password'
                    type='password'
                    size='lg'
                    placeholder='パスワード'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.password}
                    maxLength={20}
                  />
                  <FormErrorMessage>{String(step2Form.errors.password)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.passwordConfirm && Boolean(step2Form.errors.passwordConfirm)}
                >
                  <FormLabel htmlFor='passwordConfirm'>確認用パスワード</FormLabel>
                  <Input
                    id='passwordConfirm'
                    name='passwordConfirm'
                    type='password'
                    size='lg'
                    placeholder='確認用パスワード'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.passwordConfirm}
                    maxLength={20}
                  />
                  <FormErrorMessage>{String(step2Form.errors.passwordConfirm)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='tel'>電話番号</FormLabel>
                  <Input
                    id='tel'
                    name='tel'
                    type='text'
                    size='lg'
                    placeholder='電話番号'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.tel}
                    maxLength={20}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='email'>メールアドレス</FormLabel>
                  <Input
                    id='email'
                    name='email'
                    type='email'
                    size='lg'
                    placeholder='メールアドレス'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.email}
                    maxLength={100}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='birth'>生年月日</FormLabel>
                  <Input
                    id='birth'
                    name='birth'
                    type='date'
                    size='lg'
                    placeholder='生年月日'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.birth}
                  />
                </FormControl>

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.approvalPeriod && Boolean(step2Form.errors.approvalPeriod)}
                >
                  <FormLabel htmlFor='approvalPeriod'>認定有効期限</FormLabel>
                  <Input
                    id='approvalPeriod'
                    name='approvalPeriod'
                    type='date'
                    size='lg'
                    placeholder='認定有効期限'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.approvalPeriod}
                  />
                  <FormErrorMessage>{String(step2Form.errors.approvalPeriod)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.approvalAt && Boolean(step2Form.errors.approvalAt)}
                >
                  <FormLabel htmlFor='approvalAt'>認定日</FormLabel>
                  <Input
                    id='approvalAt'
                    name='approvalAt'
                    type='date'
                    size='lg'
                    placeholder='認定日'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.approvalAt}
                  />
                  <FormErrorMessage>{String(step2Form.errors.approvalAt)}</FormErrorMessage>
                </FormControl>

                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >会社情報</Heading>
                <Divider />

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='company'>会社</FormLabel>
                  <Input
                    id='company'
                    name='company'
                    type='text'
                    size='lg'
                    placeholder='会社'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.company}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='department'>部署名</FormLabel>
                  <Input
                    id='department'
                    name='department'
                    type='text'
                    size='lg'
                    placeholder='部署名'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.department}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='position'>役職</FormLabel>
                  <Input
                    id='position'
                    name='position'
                    type='text'
                    size='lg'
                    placeholder='役職'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.position}
                  />
                </FormControl>

                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >住所情報</Heading>
                <Divider />

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='addressType'>住所区分</FormLabel>
                  <Select
                    id='addressType'
                    name='addressType'
                    size='lg'
                    placeholder='住所区分'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.addressType}
                  >
                    <option value='1'>自宅</option>
                    <option value='2'>会社</option>
                  </Select>
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='zip'>郵便番号</FormLabel>
                  <Input
                    id='zip'
                    name='zip'
                    type='text'
                    size='lg'
                    placeholder='郵便番号'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.zip}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='pref'>都道府県</FormLabel>
                  <Input
                    id='pref'
                    name='pref'
                    type='text'
                    size='lg'
                    placeholder='都道府県'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.pref}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='address1'>住所1</FormLabel>
                  <Input
                    id='address1'
                    name='address1'
                    type='text'
                    size='lg'
                    placeholder='住所1'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.address1}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='address2'>住所2</FormLabel>
                  <Input
                    id='address2'
                    name='address2'
                    type='text'
                    size='lg'
                    placeholder='住所2'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.address2}
                  />
                </FormControl>

                <FormControl
                  py={5}
                >
                  <FormLabel htmlFor='address3'>住所3</FormLabel>
                  <Input
                    id='address3'
                    name='address3'
                    type='text'
                    size='lg'
                    placeholder='住所3'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.address3}
                  />
                </FormControl>

                </>
              }

            </CardBody>
            <CardFooter
            >
              {step === 1 &&
                <HStack
                  spacing={3}
                  w='full'
                  justifyContent='flex-end'
                >
                  <Button
                    as={Link}
                    to='/members'
                  >
                    キャンセル
                  </Button>
                  <Button
                    colorScheme='base'
                    type='submit'
                  >
                    基本情報入力へ
                  </Button>
                </HStack>
              }
              {step === 2 &&
                <HStack
                  spacing={3}
                  w='full'
                  justifyContent='flex-end'
                >
                  <Button
                    type='button'
                    onClick={() => setStep(1)}
                  >
                    キャンセル
                  </Button>
                  <Button
                    colorScheme='base'
                    type='submit'
                  >
                    作成する
                  </Button>
                </HStack>
              }

            </CardFooter>
          </form>
        </Card>
      </VStack>
    </Layout>
  );
};
