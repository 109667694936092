import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Center,
  Spacer,
  Box,
  HStack,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Avatar,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
  Image,
  Text,
  Show,
  Hide,
  CircularProgress,
  AbsoluteCenter,
  Divider,
} from '@chakra-ui/react';

import { BreadcrumbsDef } from 'interfaces/breadcrumbs';
// import { Index as ServicesCreateIndex } from 'views/projects/create';
import { Header } from './header';
import { Navigation } from './navigation';

interface Props {
  children?: React.ReactElement;
  breadcrumbs?: BreadcrumbsDef;
  showNavi?: boolean;
  currentNavi?: string;
  loading?: boolean;
  name?: string;
  avatar?: string;
}

export const Layout: React.FunctionComponent<Props> = ({ children, breadcrumbs, showNavi = true, currentNavi, loading = true, name = '', avatar = ''}: Props) => {
  return (
    <Container
      maxW='container.xl'
      h='100vh'
      minH={100}
    >
      <VStack
        w='full'
        h='full'
        spacing={0}
      >
        {/* Header */}
        <Header
          name={name}
          avatar={avatar}
        />
        {/* Header */}

        <HStack
          w='full'
          spacing={0}
          bg='sub.50'
          alignItems='flex-start'
        >
          {/* navigation */}
          {showNavi &&
            <Navigation
              currentNavi={currentNavi}
            />
          }
          {/* navigation */}

          <VStack
            flex={1}
            //w='full'
            h='full'
          >

            <Box
              w='full'
            >
              {loading ?
                <Center mt={{ base: 20, md: 40 }}>
                  <CircularProgress
                    isIndeterminate
                    color='base.900'
                    size={100}
                  />
                </Center>
              :
                <>
                  {children}
                </>
              }
            </Box>

            <Spacer />

            {/* footer */}
            <Center
              w='full'
              py={5}
            >
              Copyright©<Text color='base.900' as='b' display='inline' mx={2}>一般社団法人 相続診断協会</Text>All Rights Reserved.
            </Center>
            {/* footer */}

          </VStack>
        </HStack>
      </VStack>
    </Container>
  );
};
