import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Divider,
  SimpleGrid,
} from '@chakra-ui/react';

import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { InlineEditor } from 'views/components/inline_editor';
import { Show as Response } from 'interfaces/api/references/show';

export const Show = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    Fetch.get(`references/${id}`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, [id]);

  return (
    <Layout
      currentNavi='references'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <Heading size='md'>{response?.reference?.name ? response.reference.name : '参考資料詳細'}</Heading>
            <Divider mt={3} />
          </CardHeader>
          <CardBody>
            {response &&
              <>
                <InlineEditor
                  name='id'
                  value={response.reference.id}
                  title='ID'
                  isReadOnly={true}
                />

                <InlineEditor
                  name='file'
                  value={response.reference.name}
                  title='ファイル'
                  type='file'
                  url={`references/${id}`}
                  format='application/pdf'
                />

              </>
            }
          </CardBody>

          <CardFooter>
            <Button
              w='full'
              as={Link}
              to='/references'
            >
              一覧へ
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    </Layout>
  );
};
