import * as React from 'react';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  FormControl,
  FormLabel,
  SimpleGrid,
  Input,
  Button,
} from '@chakra-ui/react';
import {
  FiDownloadCloud,
} from 'react-icons/fi';
import moment from 'moment';
import DataTable from 'react-data-table-component';

import { DataTablePaginationComponentOptions } from 'config/const';
import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/check_logs/index';

const columns = [
  {
    name: '#',
    selector: (row: any) => row.id,
    grow: 2
  },
  {
    name: '会員',
    selector: (row: any) => `${row.memberID}${row.memberName ? ` (${row.memberName})` : ''}`,
    grow: 2
  },
  {
    name: '更新チェック日時',
    selector: (row: any) => row.createAt ? moment.unix(row.createAt).format('YYYY/MM/DD HH:mm') : '-',
    center: true,
    grow: 2
  },
];

export const Index = () => {
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [length, setLength] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [searchWord, setSearchWord] = React.useState('');
  const [searchFrom, setSearchFrom] = React.useState('');
  const [searchTo, setSearchTo] = React.useState('');
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLength(newPerPage);
  };

  const handleSearchReset = () => {
    setPage(1);
    setSearchWord('');
    setSearchFrom('');
    setSearchTo('');
    setSearch(undefined);
  };

  const handleSearchSubmit = () => {
    setPage(1);
    const searchs = [];

    if (searchWord) {
      searchs.push(`word=${searchWord}`);
    }

    if (searchFrom) {
      searchs.push(`from=${moment(searchFrom).unix()}`);
    }

    if (searchTo) {
      searchs.push(`to=${moment(searchTo).unix()}`);
    }

    if (searchs.length > 0) {
      setSearch(searchs.join('&'));
    } else {
      setSearch(undefined);
    }
  };

  const downloadExport = () => {
    Fetch.download(`check_logs/export${search ? `?${search}` : ''}`, '更新チェック履歴.csv');
  };

  React.useEffect(() => {
    (async() => {
      await Fetch.get(`check_logs?length=${length}&page=${page}${search ? `&${search}` : ''}`).then(res => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
        setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);
      });
    })()
  }, [length, page, search]);

  return (
    <Layout
      currentNavi='check_logs'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>更新チェック履歴</Heading>
              <Spacer />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='green'
                aria-label='download'
                fontSize={20}
                icon={<FiDownloadCloud />}
                onClick={downloadExport}
                isDisabled={!response || response.checkLogs.length === 0}
              />
            </HStack>
          </CardHeader>
          <CardBody>

            <Accordion
              allowToggle
              mb={5}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: 'base.700', color: 'white' }}>
                    <Box as="span" flex='1' textAlign='left'>
                      詳細検索
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <FormControl
                    my={3}
                  >
                    <FormLabel>検索キーワード</FormLabel>
                    <Input
                      id='searchWord'
                      name='searchWord'
                      type='text'
                      placeholder='検索キーワード'
                      onChange={(e: any) => setSearchWord(e.target.value)}
                      value={searchWord}
                    />
                  </FormControl>

                  <FormControl
                    my={3}
                  >
                    <FormLabel>更新チェック期間</FormLabel>
                    <SimpleGrid
                      columns={2}
                      spacing={10}
                    >
                      <Input
                        id='searchFrom'
                        name='searchFrom'
                        type='datetime-local'
                        placeholder='更新チェック期間'
                        onChange={(e: any) => setSearchFrom(e.target.value)}
                        value={searchFrom}
                      />

                      <Input
                        id='searchTo'
                        name='searchTo'
                        type='datetime-local'
                        placeholder='更新チェック期間'
                        onChange={(e: any) => setSearchTo(e.target.value)}
                        value={searchTo}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <SimpleGrid
                    my={5}
                    columns={2}
                    spacing={5}
                  >
                    <Button onClick={handleSearchReset}>リセット</Button>
                    <Button
                      bg="base.700"
                      color="white"
                      colorScheme="green"
                      onClick={handleSearchSubmit}
                    >検索</Button>
                  </SimpleGrid>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            {(response && response.checkLogs.length > 0) ?
              <DataTable
                columns={columns}
                data={response?.checkLogs}
                highlightOnHover
                pagination
                paginationServer
                fixedHeader
                fixedHeaderScrollHeight="300px"
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationTotalRows={response?.countTotal}
                paginationComponentOptions={DataTablePaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                paginationPerPage={length}
              />
            :
              <>データがありません</>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
