export const LocalStorage = {
  async get(key: string) {
    const value = localStorage.getItem(key);

    if (value === null) return undefined;

    if (value === 'null') return null;
    if (value === 'undefined') return undefined;

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  },

  async set(key: string, value?: string | object) {
    if (!value || value === '') {
      localStorage.removeItem(key);
    } else {
      if (typeof value === 'object') {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, value);
      }
    }
  }
};
