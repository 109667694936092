import enums from './enums.json';
export const Enums = enums.enums;

export const APP_NAME     = process.env.REACT_APP_NAME;
export const BASE_URL     = process.env.REACT_APP_BASE_URL;
export const API_URL      = process.env.REACT_APP_API_URL;
export const STORAGE_URL  = process.env.REACT_APP_STORAGE_URL;
export const ANALYTICS_ID = process.env.REACT_APP_ANALYTICS_ID;

export const STAFF_TOKEN_HEADER = 'X-JIDA-STAFF-TOKEN';
export const STAFF_TOKEN_LOCAL_KEY = 'jida_staff_token';

export const GetEnumsName = (model: any, id: number) => {
  let name = '';
  Object.keys(model).forEach((key) => {
    if (model[key]['id'] === id) {
      name = model[key]['ja'];
      return false;
    }
  });

  return name;
};

export const DataTablePaginationComponentOptions = {
	rowsPerPageText: 'ページ表示件数',
	rangeSeparatorText: '合計件数:'
};
