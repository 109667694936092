import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  IconButton,
  Spacer,
  Box,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Image,
  Hide,
  Center,
} from '@chakra-ui/react';
import {
  FiMenu,
} from 'react-icons/fi';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { LocalStorage } from 'utils/local_stoage';
import { STAFF_TOKEN_LOCAL_KEY } from 'config/const';

interface Props {
  name?: string;
  avatar?: string;
}

export const Header: React.FunctionComponent<Props> = ({ name = 'スタッフ', avatar = '' }: Props) => {
  const navigate = useNavigate();
  const { sendToast } = useToast();

  const handleLogout = () => {
    Fetch.delete('logout').then(res => {
      sendToast({title: 'ログアウトしました', description: '', status: 'success'});
      LocalStorage.set(STAFF_TOKEN_LOCAL_KEY);
      navigate('/login');
      return false;
    });
  };

  return (
    <HStack
      h={50}
      w='full'
      spacing={0}
      bg='base.700'
    >
      <HStack
        w={{ base: '50px', md: '200px' }}
        bg='white'
      >
        <Hide above='md'>
          <Image src='/static/images/logo/mark.jpg' w='50px' />
        </Hide>
        <Hide below='md'>
          <Center>
            <Image src='/static/images/logo/logo.png' h='50px' />
          </Center>
        </Hide>
      </HStack>

      <HStack
        flex={1}
        id='contentHeader'
      >
        <IconButton
          aria-label="mode"
          colorScheme="primary"
          icon={<FiMenu size={24} />}
          ml={3}
        />

        <Spacer />

        <Box
          pr={3}
          py={1}
        >
          <Menu>
            <MenuButton
              as={Avatar}
              name={name}
              src={avatar}
              bg='orange.500'
            />
            <MenuList>
              <MenuItem
                as={Link}
                to='/my'
              >プロフィール</MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={handleLogout}
              >ログアウト</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>

    </HStack>
  );
};
