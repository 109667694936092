import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/my/index';

const step1ValidationSchemas = yup.object({
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('メールアドレスを正しく入力してください'),
});

const step2ValidationSchemas = yup.object({
  name: yup
    .string()
    .required('お名前を入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードを正しく入力してください')
    .max(20, 'パスワードを正しく入力してください'),
  passwordConfirm: yup
    .string()
    .required('確認用パスワードを入力してください')
    .min(8, 'パスワードを正しく入力してください')
    .max(20, 'パスワードを正しく入力してください')
    .oneOf([yup.ref('password')], 'パスワードが一致しません')
});

export const Create = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [step, setStep] = React.useState<number>(1);
  const { sendToast } = useToast();

  const step1Form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: step1ValidationSchemas,
    onSubmit: (values: any) => {
      // メールアドレスの重複チェック
      Fetch.get(`staffs/validate?email=${values.email}`).then(res => {
        if (res?.error) {
          sendToast({title: 'メールアドレス重複エラー', description: '入力されたメールアドレスは既に使用されています。他のメールアドレスを入力してください。', status: 'error'});
          return false;
        }

        setStep(2);
      });
    },
  });

  const step2Form = useFormik({
    initialValues: {
      name: '',
      password: '',
      passwordConfirm: ''
    },
    validationSchema: step2ValidationSchemas,
    onSubmit: (values: any) => {
      const staff = {
        email: step1Form.values.email,
        name: values.name,
        password: values.password
      };


      Fetch.post('staffs', staff).then(res => {
        if (res?.error) {
          // setFormLoading(false);
          sendToast({title: '作成失敗しました', description: '', status: 'error'});
          return false;
        }

        navigate('/staffs');
        // setFormLoading(false);
        return false;
      });
    },
  });

  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, []);

  return (
    <Layout
      currentNavi='staffs'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >

        <Card
          w='full'
          h='full'
        >
          <form onSubmit={(step === 1) ? step1Form.handleSubmit : step2Form.handleSubmit} noValidate>
            <CardHeader>
              <Heading size='md'>スタッフ新規作成</Heading>
            </CardHeader>
            <CardBody>
              <FormControl
                isRequired
                pb={3}
                isInvalid={step1Form.touched.email && Boolean(step1Form.errors.email)}
              >
                <FormLabel htmlFor='email'>メールアドレス</FormLabel>
                <Input
                  id='email'
                  name='email'
                  type='email'
                  size='lg'
                  placeholder='メールアドレス'
                  isRequired
                  onChange={step1Form.handleChange}
                  value={step1Form.values.email}
                  maxLength={50}
                  isDisabled={step === 2}
                />
                <FormErrorMessage>{String(step1Form.errors.email)}</FormErrorMessage>
              </FormControl>

              {step === 2 &&
                <>
                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.name && Boolean(step2Form.errors.name)}
                >
                  <FormLabel htmlFor='name'>お名前</FormLabel>
                  <Input
                    id='name'
                    name='name'
                    type='text'
                    size='lg'
                    placeholder='お名前'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.name}
                    maxLength={20}
                  />
                  <FormErrorMessage>{String(step2Form.errors.name)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.password && Boolean(step2Form.errors.password)}
                >
                  <FormLabel htmlFor='password'>パスワード</FormLabel>
                  <Input
                    id='password'
                    name='password'
                    type='password'
                    size='lg'
                    placeholder='パスワード'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.password}
                    maxLength={20}
                  />
                  <FormErrorMessage>{String(step2Form.errors.password)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  py={5}
                  isInvalid={step2Form.touched.passwordConfirm && Boolean(step2Form.errors.passwordConfirm)}
                >
                  <FormLabel htmlFor='passwordConfirm'>確認用パスワード</FormLabel>
                  <Input
                    id='passwordConfirm'
                    name='passwordConfirm'
                    type='password'
                    size='lg'
                    placeholder='確認用パスワード'
                    onChange={step2Form.handleChange}
                    value={step2Form.values.passwordConfirm}
                    maxLength={20}
                  />
                  <FormErrorMessage>{String(step2Form.errors.passwordConfirm)}</FormErrorMessage>
                </FormControl>
                </>
              }

            </CardBody>
            <CardFooter
            >
              {step === 1 &&
                <HStack
                  spacing={3}
                  w='full'
                  justifyContent='flex-end'
                >
                  <Button
                    as={Link}
                    to='/staffs'
                  >
                    キャンセル
                  </Button>
                  <Button
                    colorScheme='base'
                    type='submit'
                  >
                    基本情報入力へ
                  </Button>
                </HStack>
              }
              {step === 2 &&
                <HStack
                  spacing={3}
                  w='full'
                  justifyContent='flex-end'
                >
                  <Button
                    type='button'
                    onClick={() => setStep(1)}
                  >
                    キャンセル
                  </Button>
                  <Button
                    colorScheme='base'
                    type='submit'
                  >
                    作成する
                  </Button>
                </HStack>
              }

            </CardFooter>
          </form>
        </Card>
      </VStack>
    </Layout>
  );
};
