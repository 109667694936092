import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Divider,
  SimpleGrid,
} from '@chakra-ui/react';

import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { InlineEditor } from 'views/components/inline_editor';
import { Show as Response } from 'interfaces/api/seminars/show';

export const Show = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    Fetch.get(`seminars/${id}`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, [id]);

  return (
    <Layout
      currentNavi='seminars'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <Heading size='md'>{response?.seminar?.title ? response.seminar.title : 'セミナー詳細'}</Heading>
            <Divider mt={3} />
          </CardHeader>
          <CardBody>
            {response &&
              <>
                <InlineEditor
                  name='id'
                  value={response.seminar.id}
                  title='ID'
                  isReadOnly={true}
                />

                <InlineEditor
                  name='title'
                  value={response.seminar.title}
                  title='タイトル'
                  type='text'
                  url={`seminars/${id}`}
                  isRequired={true}
                />

                <InlineEditor
                  name='url'
                  value={response.seminar.url}
                  title='リンク'
                  type='url'
                  url={`seminars/${id}`}
                  isRequired={true}
                />
              </>
            }
          </CardBody>

          <CardFooter>
            <Button
              w='full'
              as={Link}
              to='/seminars'
            >
              一覧へ
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    </Layout>
  );
};
