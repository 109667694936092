import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  IconButton,
  Button,
  FormLabel,
  SimpleGrid,
  Input,
  Icon,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import {
  FiList,
  FiFileText,
} from 'react-icons/fi';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';

import { Enums, GetEnumsName, DataTablePaginationComponentOptions } from 'config/const';
import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as MyResponse } from 'interfaces/api/my';
import { Import as ImportResponse } from 'interfaces/api/members/import';
import { CsvMember } from 'interfaces/models/member';


export const Index = () => {
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const [myResponse, setMyResponse] = React.useState<MyResponse | undefined>(undefined);
  const [importResponse, setImportResponse] = React.useState<ImportResponse | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);
  const [previewFile, setPreviewFile] = React.useState({ filename: '', raw: '' });

  const changeFile = (e: any) => {
    e.preventDefault();

    if (e.target.files.length) {
      setPreviewFile({
        filename: e.target.files[0].name,
        raw: e.target.files[0]
      });
    }
  };

  const handleUpload = (e: any) => {
    e.preventDefault();

    if (!previewFile || !previewFile.filename) {
      return false;
    }

    let csv = new FormData();
    csv.append('file', previewFile.raw);

    Fetch.post('members/import', csv).then(res => {
      if (res?.error) {
        sendToast({title: 'アップロード失敗しました', description: 'ファイルを確認してください', status: 'error'});
        return false;
      }

      setImportResponse(res);
    });
  };

  const handleUpdate = (e: any) => {
    if (!previewFile || !previewFile.filename) {
      return false;
    }

    let csv = new FormData();
    csv.append('file', previewFile.raw);

    Fetch.post('members/import/complete', csv).then(res => {
      if (res?.error) {
        sendToast({title: 'アップロード失敗しました', description: 'ファイルを確認してください', status: 'error'});
        return false;
      }

      navigate('/members');
    });
  };


  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setMyResponse(res);
      setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);
    });
  }, []);

  return (
    <Layout
      currentNavi='members'
      loading={loading}
      name={myResponse?.my?.name}
      avatar={myResponse?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>会員CSVインポート</Heading>
              <Spacer />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='green'
                aria-label='list'
                fontSize={20}
                icon={<FiList />}
                as={Link}
                to='/members'
              />
            </HStack>
          </CardHeader>
          <CardBody>
            <a href='/static/templates/template_members.csv' download='会員テンプレート.csv'>
              <Text color='base.700'>会員CSVテンプレートダウンロード</Text>
            </a>

            {(importResponse?.members && importResponse.members.length > 0) ?
            <>
            <TableContainer mt={5}>
              <Table variant='simple'>
                <TableCaption>インポート会員一覧</TableCaption>
                <Thead>
                  <Tr>
                    <Th>区分</Th>
                    <Th>会員ID</Th>
                    <Th>会員名</Th>
                    <Th>フリガナ</Th>
                    <Th>認定有効期限</Th>
                    <Th>認定日</Th>
                    <Th>会社</Th>
                    <Th>部署名</Th>
                    <Th>役職</Th>
                    <Th>住所区分</Th>
                    <Th>郵便番号</Th>
                    <Th>都道府県</Th>
                    <Th>住所1</Th>
                    <Th>住所2</Th>
                    <Th>住所3</Th>
                    <Th>電話番号</Th>
                    <Th>メールアドレス</Th>
                    <Th>生年月日</Th>
                    <Th>メモ</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {importResponse.members.map((member: CsvMember, index: number) => (
                    <>
                    <Tr key={index} bg={member.kind === 1 ? 'lightblue' : ''}>
                      <Td
                        rowSpan={member.kind}
                        bg={member.kind === 1 ? 'lightblue' : 'lightgreen'}
                      >{member.kind === 1 ? '新規' : '更新'}</Td>
                      <Td rowSpan={member.kind}>{member?.after?.id === 0 ? '-' : member?.after?.id}</Td>
                      <Td bg={member.kind === 2 && member?.before?.name !== member?.after?.name ? 'lightgreen' : ''}>{member?.after?.name}</Td>
                      <Td bg={member.kind === 2 && member?.before?.kana !== member?.after?.kana ? 'lightgreen' : ''}>{member?.after?.kana}</Td>
                      <Td bg={member.kind === 2 && member?.before?.approvalPeriod !== member?.after?.approvalPeriod ? 'lightgreen' : ''}>{member?.after?.approvalPeriod}</Td>
                      <Td bg={member.kind === 2 && member?.before?.approvalAt !== member?.after?.approvalAt ? 'lightgreen' : ''}>{member?.after?.approvalAt}</Td>
                      <Td bg={member.kind === 2 && member?.before?.company !== member?.after?.company ? 'lightgreen' : ''}>{member?.after?.company}</Td>
                      <Td bg={member.kind === 2 && member?.before?.department !== member?.after?.department ? 'lightgreen' : ''}>{member?.after?.department}</Td>
                      <Td bg={member.kind === 2 && member?.before?.position !== member?.after?.position ? 'lightgreen' : ''}>{member?.after?.position}</Td>
                      <Td bg={member.kind === 2 && member?.before?.addressType !== member?.after?.addressType ? 'lightgreen' : ''}>{member?.after?.addressType}</Td>
                      <Td bg={member.kind === 2 && member?.before?.zip !== member?.after?.zip ? 'lightgreen' : ''}>{member?.after?.zip}</Td>
                      <Td bg={member.kind === 2 && member?.before?.pref !== member?.after?.pref ? 'lightgreen' : ''}>{member?.after?.pref}</Td>
                      <Td bg={member.kind === 2 && member?.before?.address1 !== member?.after?.address1 ? 'lightgreen' : ''}>{member?.after?.address1}</Td>
                      <Td bg={member.kind === 2 && member?.before?.address2 !== member?.after?.address2 ? 'lightgreen' : ''}>{member?.after?.address2}</Td>
                      <Td bg={member.kind === 2 && member?.before?.address3 !== member?.after?.address3 ? 'lightgreen' : ''}>{member?.after?.address3}</Td>
                      <Td bg={member.kind === 2 && member?.before?.tel !== member?.after?.tel ? 'lightgreen' : ''}>{member?.after?.tel}</Td>
                      <Td bg={member.kind === 2 && member?.before?.email !== member?.after?.email ? 'lightgreen' : ''}>{member?.after?.email}</Td>
                      <Td bg={member.kind === 2 && member?.before?.birth !== member?.after?.birth ? 'lightgreen' : ''}>{member?.after?.birth}</Td>
                      <Td bg={member.kind === 2 && member?.before?.memo !== member?.after?.memo ? 'lightgreen' : ''}>{member?.after?.memo}</Td>
                    </Tr>
                    {member.kind === 2 &&
                      <Tr key={index}>
                        <Td>{member?.before?.name}</Td>
                        <Td>{member?.before?.kana}</Td>
                        <Td>{member?.before?.approvalPeriod}</Td>
                        <Td>{member?.before?.approvalAt}</Td>
                        <Td>{member?.before?.company}</Td>
                        <Td>{member?.before?.department}</Td>
                        <Td>{member?.before?.position}</Td>
                        <Td>{member?.before?.addressType}</Td>
                        <Td>{member?.before?.zip}</Td>
                        <Td>{member?.before?.pref}</Td>
                        <Td>{member?.before?.address1}</Td>
                        <Td>{member?.before?.address2}</Td>
                        <Td>{member?.before?.address3}</Td>
                        <Td>{member?.before?.tel}</Td>
                        <Td>{member?.before?.email}</Td>
                        <Td>{member?.before?.birth}</Td>
                        <Td>{member?.before?.memo}</Td>
                      </Tr>
                    }
                    </>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>

            <SimpleGrid
                mt={5}
                columns={2}
                spacing={10}
                w='full'
              >
                <Button
                  as={Link}
                  to='/members'
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='base'
                  onClick={handleUpdate}
                >
                  更新する
                </Button>
              </SimpleGrid>
            </>
            :
              <>
              <FormLabel
                mt={5}
              >
                <VStack
                  w='full'
                  h={150}
                  spacing={5}
                  p={5}
                  borderWidth={1}
                  borderColor='base.500'
                  justifyItems='center'
                >
                  {previewFile.filename ?
                    <Text fontSize='xl'>{previewFile.filename}</Text>
                  :
                    <Icon
                      as={FiFileText}
                      w={20}
                      h={20}
                    />
                  }
                  <Text>ここをクリックしてCSVファイルをアップロードしてください</Text>
                </VStack>
                <Input
                  sx={{ display: 'none' }}
                  type='file'
                  accept='text/csv'
                  onChange={changeFile}
                />
              </FormLabel>

              <SimpleGrid
                mt={5}
                columns={2}
                spacing={10}
                w='full'
              >
                <Button
                  as={Link}
                  to='/members'
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='base'
                  isDisabled={!previewFile.filename}
                  onClick={handleUpload}
                >
                  確認する
                </Button>
              </SimpleGrid>
              </>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
