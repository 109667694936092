import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  IconButton,
  Spacer,
  Box,
  HStack,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Avatar,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
  Image,
  Text,
  Show,
  Hide,
  CircularProgress,
  AbsoluteCenter,
  Center,
  Divider,
} from '@chakra-ui/react';
import {
  FiHome,
  FiUsers,
  FiRadio,
  FiCreditCard,
  FiCheckSquare,
  FiGithub,
  FiCheckCircle,
  FiDownload,
  FiShare2,
  FiSliders,
  FiBook,
  FiClipboard,
  FiCpu,
  FiBarChart,
  FiEye,
  FiChevronUp,
  FiChevronDown,
  FiDatabase,
  FiTrendingUp,
  FiAlertOctagon,
  FiGrid,
  FiFileText,
  FiBell,
  FiLayers,
  FiLayout,
  FiCoffee,
} from 'react-icons/fi';

interface Props {
  currentNavi?: string;
}

export const Navigation: React.FunctionComponent<Props> = ({ currentNavi }: Props) => {
  return (
    <VStack
      minW={{ base: '50px', md: '200px' }}
      minH={300}
      spacing={0}
    >
      <HStack
        pl={5}
        as={Link}
        to='/'
        w='full'
        h={50}
        bg={!currentNavi || currentNavi === 'dashboard' ? 'base.700' : undefined}
        color={!currentNavi || currentNavi === 'dashboard' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiHome} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>ダッシュボード</Text>
        </Hide>
      </HStack>

      <Divider
        borderColor='base.100'
      />

      <HStack
        pl={5}
        as={Link}
        to='/members'
        w='full'
        h={50}
        bg={currentNavi === 'members' ? 'base.700' : undefined}
        color={currentNavi === 'members' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiUsers} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>会員</Text>
        </Hide>
      </HStack>

      <HStack
        pl={5}
        as={Link}
        to='/welcomes'
        w='full'
        h={50}
        bg={currentNavi === 'welcomes' ? 'base.700' : undefined}
        color={currentNavi === 'welcomes' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiRadio} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>お知らせ</Text>
        </Hide>
      </HStack>

      <Divider
        borderColor='base.100'
      />

      <HStack
        pl={5}
        as={Link}
        to='/payments'
        w='full'
        h={50}
        bg={currentNavi === 'payments' ? 'base.700' : undefined}
        color={currentNavi === 'payments' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiCreditCard} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>決済履歴</Text>
        </Hide>
      </HStack>

      <Divider
        borderColor='base.100'
      />

      <HStack
        pl={5}
        as={Link}
        to='/checks'
        w='full'
        h={50}
        bg={currentNavi === 'checks' ? 'base.700' : undefined}
        color={currentNavi === 'checks' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiSliders} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>チェック項目</Text>
        </Hide>
      </HStack>

      <HStack
        pl={5}
        as={Link}
        to='/exam_logs'
        w='full'
        h={50}
        bg={currentNavi === 'exam_logs' ? 'base.700' : undefined}
        color={currentNavi === 'exam_logs' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiCheckCircle} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>シート利用履歴</Text>
        </Hide>
      </HStack>

      <HStack
        pl={5}
        as={Link}
        to='/check_logs'
        w='full'
        h={50}
        bg={currentNavi === 'check_logs' ? 'base.700' : undefined}
        color={currentNavi === 'check_logs' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiCheckSquare} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>更新チェック履歴</Text>
        </Hide>
      </HStack>

      <Divider
        borderColor='base.100'
      />

      <HStack
        pl={5}
        as={Link}
        to='/downloads'
        w='full'
        h={50}
        bg={currentNavi === 'downloads' ? 'base.700' : undefined}
        color={currentNavi === 'downloads' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiDownload} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>ダウンロード</Text>
        </Hide>
      </HStack>

      <HStack
        pl={5}
        as={Link}
        to='/references'
        w='full'
        h={50}
        bg={currentNavi === 'references' ? 'base.700' : undefined}
        color={currentNavi === 'references' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiFileText} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>参考資料</Text>
        </Hide>
      </HStack>

      <HStack
        pl={5}
        as={Link}
        to='/seminars'
        w='full'
        h={50}
        bg={currentNavi === 'seminars' ? 'base.700' : undefined}
        color={currentNavi === 'seminars' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiShare2} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>セミナー</Text>
        </Hide>
      </HStack>

      <Divider
        borderColor='base.100'
      />

      <HStack
        pl={5}
        as={Link}
        to='/staffs'
        w='full'
        h={50}
        bg={currentNavi === 'staffs' ? 'base.700' : undefined}
        color={currentNavi === 'staffs' ? 'opposite' : 'base.700'}
      >
        <Icon as={FiGithub} w={7} h={7} />
        <Hide below='md'>
          <Text ml={3}>スタッフ</Text>
        </Hide>
      </HStack>
    </VStack>
  );
};
