import { createStandaloneToast, UseToastOptions } from '@chakra-ui/toast';

const { toast } = createStandaloneToast();

export const useToast = () => {
  const sendToast = (options: UseToastOptions) => {
    toast({
      title: options.title,
      description: options.description,
      status: options.status,
      duration: 3000,
      isClosable: true,
    });
  };

  return {
    sendToast
  };
};
