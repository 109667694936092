import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  Avatar,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
} from '@chakra-ui/react';
import {
  FiPlus,
  FiShare,
  FiTrash2,
} from 'react-icons/fi';
import moment from 'moment';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/staffs/index';
import { Staff } from 'interfaces/models/staff';
import { Enums, GetEnumsName } from 'config/const';

export const Index = () => {
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);
  const [staffs, setStaffs] = React.useState<undefined | Staff[]>(undefined);
  const [deleteItems, setDeleteItems] = React.useState<Array<{[key: string]: string}>>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleDeleteItem = (e: any, id: string, name: string) => {
    if (e.target.checked) {
      setDeleteItems([...deleteItems, {id: id, name: name}]);
    } else {
      setDeleteItems(deleteItems.filter(item => item.id !== id));
    }
  };

  const handleDeleteItems = () => {
    if (deleteItems.length === 0) return;

    let request: Array<string> = [];
    deleteItems.forEach((item, _) => {
      request.push(item.id);
    });

    Fetch.post('staffs/delete', request).then(res => {
      if (res?.error) {
        sendToast({title: '削除失敗しました', description: '', status: 'error'});
        return false;
      }

      setStaffs(staffs?.filter(staff => !request.includes(staff.id)));
      setDeleteItems([]);
      setIsOpen(false);
    });
  };

  React.useEffect(() => {
    (async() => {
      await Fetch.get('staffs').then(res => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
        setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);

        if (res?.staffs.length > 0) {
          setStaffs(res.staffs);
        }
      });
    })()
  }, []);


  return (
    <>
    <Layout
      currentNavi='staffs'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>スタッフ一覧</Heading>
              <Spacer />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='base'
                aria-label='plus'
                fontSize={20}
                icon={<FiPlus />}
                as={Link}
                to='/staffs/create'
              />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='red'
                aria-label='plus'
                fontSize={20}
                icon={<FiTrash2 />}
                isDisabled={deleteItems.length === 0}
                onClick={() => setIsOpen(true)}
              />
            </HStack>
          </CardHeader>
          <CardBody>
            {(staffs && staffs.length > 0) &&
              <TableContainer>
                <Table variant='striped'>
                  <TableCaption>スタッフ一覧</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>メールアドレス</Th>
                      <Th>お名前</Th>
                      <Th>状態</Th>
                      <Th>最終アクセス</Th>
                      <Th>更新日時</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {staffs.map((staff: Staff, index: number) => (
                      <Tr>
                        <Td>
                            <HStack>
                              <>
                              <Checkbox
                                mr={2}
                                colorScheme='red'
                                size='lg'
                                onChange={(e) => handleDeleteItem(e, staff.id, staff.name)}
                              />
                              <Icon
                                as={FiShare}
                                color='green.700'
                                w={5}
                                h={5}
                                onClick={() => navigate(`/staffs/${staff.id}`)}
                              />
                              {staff.id}
                              </>
                            </HStack>
                        </Td>
                        <Td>
                            {staff.email}
                        </Td>
                        <Td>
                            {staff.name}
                        </Td>
                        <Td>
                            {GetEnumsName(Enums['staffs']['status'], staff.status)}
                        </Td>
                        <Td>
                            {staff.latestAccessAt ? moment.unix(staff.latestAccessAt).format('YYYY/MM/DD HH:mm') : '-'}
                        </Td>
                        <Td>{staff.updateAt ? moment.unix(staff.updateAt).format('YYYY/MM/DD HH:mm') : '-'}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(true)}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>データを削除しますか？</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant='striped'>
            <Tbody>
              {deleteItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{item.name}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => setIsOpen(false)}>
            キャンセル
          </Button>
          <Button
            colorScheme='red'
            onClick={handleDeleteItems}
          >削除する</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};
