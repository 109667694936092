import * as React from 'react';
import {
  HStack,
  VStack,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Text,
  Center,
  InputGroup,
  InputRightElement,
  Textarea,
  Icon,
  Avatar,
  Spacer,
} from '@chakra-ui/react';
import {
  FiX,
  FiCheck,
  FiEdit3,
  FiFileText,
} from 'react-icons/fi';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Enums, GetEnumsName } from 'config/const';

interface Props {
  name: string;
  value: undefined | string | number;
  title: string;
  isReadOnly?: boolean;
  isRequired?: boolean;
  url?: string;
  type?: 'text' | 'number' | 'email' | 'textarea' | 'avatar' | 'file' | 'url' | 'enums';
  format?: string;
  enums?: { [key: string]: { id: number, ja: string }};
}

interface Updates {
  [key: string]: undefined | string | number;
};

export const InlineEditor: React.FunctionComponent<Props> = ({ name, value, title, isReadOnly = false, isRequired = false, url = '', type = 'text', format = '', enums }: Props) => {
  const { sendToast } = useToast();
  const [mode, setMode] = React.useState<string>('preview');
  const [newValue, setNewValue] = React.useState(value);

  const [previewFile, setPreviewFile] = React.useState({ preview: '', raw: '', filename: '' });

  const changeFile = (e: any) => {
    e.preventDefault();

    if (e.target.files.length) {
      setPreviewFile({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        filename: e.target.files[0].name,
      });
      setMode('edit');
    }
  };

  /*
  const updatePreviewImage = () => {
    let updates = new FormData();
    updates.append('id', resourceID);
    updates.append('column', resourceColumnCode);
    updates.append('file', previewImage.raw);

    Fetch.upload(`{default_project}/databases/${databaseCode}/resources/upload`, updates).then(res => {
      if (res?.error) {
        return null;
      }

      setOpenConfirm(false);
    });
  };
  */

  const handleChangeMode = (mode: string) => {
    setMode(mode);
  };

  const handleSubmit = () => {
    if (type === 'text' || type === 'email' || type === 'number' || type === 'textarea' || type === 'url' || type === 'enums') {
      let _newValue = newValue;

      if (_newValue === value) {
        setMode('preview');
        return false;
      }

      if (type === 'enums') {
        _newValue = Number(_newValue);
      }

      const updates: Updates = {};
      updates[name] = _newValue;


      Fetch.post(url, updates).then(res => {
        if (res?.error) {
          sendToast({title: `${title}の更新が失敗しました`, description: '', status: 'error'});
          return false;
        }

        sendToast({title: `${title}が更新されました`, description: '', status: 'success'});
        setMode('preview');
      });
    }


    if (type === 'avatar' || type === 'file') {
      const _type = type === 'file' ? 'ファイル' : 'アバター';
      let updates = new FormData();
      updates.append(name, previewFile.raw);

      Fetch.upload(url, updates).then(res => {
        if (res?.error) {
          sendToast({title: `${_type}の更新が失敗しました`, description: '', status: 'error'});
          return false;
        }

        sendToast({title: `${_type}が更新されました`, description: '', status: 'success'});
        setNewValue(previewFile.preview);
        setMode('preview');
      });
    }
  }

  return (
    <>
    {(type === 'text' || type === 'email' || type === 'number' || type === 'url') &&
      <>
      <FormControl
        isRequired={isRequired}
        py={3}
      >
        <FormLabel>{title}</FormLabel>
        <InputGroup>
          <Input
            type='text'
            size='lg'
            placeholder={title}
            isRequired={isRequired}
            onChange={(e: any) => setNewValue(e.target.value)}
            value={newValue}
            isReadOnly={mode === 'preview'}
            focusBorderColor={mode === 'preview' ? 'white' : 'blue.500'}
            borderColor={mode === 'preview' ? 'white' : 'gray.300'}
          />
          {!isReadOnly &&
            <InputRightElement
              pt={2}
            >
              {mode === 'preview' &&
                <Icon
                  as={FiEdit3}
                  w={5}
                  h={5}
                  color='blue.500'
                  onClick={() => handleChangeMode('edit')}
                />
              }
              {mode === 'edit' &&
                <>
                <Icon
                  as={FiX}
                  w={5}
                  h={5}
                  color='gray.300'
                  onClick={() => handleChangeMode('preview')}
                />
                <Icon
                  as={FiCheck}
                  ml={1}
                  mr={5}
                  w={5}
                  h={5}
                  color='green.500'
                  onClick={handleSubmit}
                />
                </>
              }
            </InputRightElement>
          }
        </InputGroup>
      </FormControl>
      </>
    }

    {(type === 'textarea') &&
      <FormControl
        isRequired={isRequired}
        py={3}
      >
        <FormLabel>{title}</FormLabel>
        <Textarea
          placeholder={title}
          isRequired={isRequired}
          onChange={(e: any) => setNewValue(e.target.value)}
          value={newValue}
          rows={10}
          isReadOnly={mode === 'preview'}
        />

        <Center
          mt={3}
        >
        {mode === 'preview' &&
          <Icon
            as={FiEdit3}
            w={5}
            h={5}
            color='blue.500'
            onClick={() => handleChangeMode('edit')}
          />
        }
        {mode === 'edit' &&
          <>
          <Icon
            as={FiX}
            w={5}
            h={5}
            color='gray.300'
            onClick={() => handleChangeMode('preview')}
          />
          <Icon
            as={FiCheck}
            ml={1}
            mr={5}
            w={5}
            h={5}
            color='green.500'
            onClick={handleSubmit}
          />
          </>
        }
        </Center>

      </FormControl>
    }

    {(type === 'enums') &&
      <>
      <FormControl
        isRequired={isRequired}
        py={3}
      >
        <FormLabel>{title}</FormLabel>
        <InputGroup>
          <Select
            mr={16}
            onChange={(e: any) => setNewValue(e.target.value)}
            value={newValue}
            isDisabled={mode === 'preview'}
          >
            {enums && Object.entries(enums).map(([key, item]) => (
              <option key={key} value={item.id}>{item.ja}</option>
            ))}
          </Select>
          {!isReadOnly &&
            <InputRightElement
              pt={2}
            >
              {mode === 'preview' &&
                <Icon
                  as={FiEdit3}
                  w={5}
                  h={5}
                  color='blue.500'
                  onClick={() => handleChangeMode('edit')}
                />
              }
              {mode === 'edit' &&
                <>
                <Icon
                  as={FiX}
                  w={5}
                  h={5}
                  color='gray.300'
                  onClick={() => handleChangeMode('preview')}
                />
                <Icon
                  as={FiCheck}
                  ml={1}
                  mr={5}
                  w={5}
                  h={5}
                  color='green.500'
                  onClick={handleSubmit}
                />
                </>
              }
            </InputRightElement>
          }
        </InputGroup>
      </FormControl>
      </>
    }

    {(type === 'avatar') &&
      <HStack
        py={3}
      >
        <FormLabel>
          <Input
            sx={{ display: 'none' }}
            type='file'
            accept='image/*'
            onChange={changeFile}
          />

          {(mode === 'edit' && previewFile.preview) ?
            <Avatar
              name={title}
              src={previewFile.preview}
              bg='orange.500'
            />
          :
            <Avatar
              name={title}
              src={String(newValue)}
              bg='orange.500'
            />
          }

        </FormLabel>
        <Spacer />
        {mode === 'edit' &&
          <>
          <Icon
            as={FiX}
            w={5}
            h={5}
            color='gray.300'
            onClick={() => handleChangeMode('preview')}
          />
          <Icon
            as={FiCheck}
            ml={1}
            mr={5}
            w={5}
            h={5}
            color='green.500'
            onClick={handleSubmit}
          />
          </>
        }
      </HStack>
    }

    {(type === 'file') &&
      <HStack
        py={3}
      >
        <FormLabel>
          <VStack
            maxW='500px'
            h={150}
            spacing={5}
            p={5}
            borderWidth={1}
            borderColor='base.500'
          >
            {previewFile.filename ?
              <Text fontSize='6xl'>{previewFile.filename}</Text>
            :
              <Icon
                as={FiFileText}
                w={20}
                h={20}
              />
            }
            <Text>ここをクリックしてファイルをアップロードしてください</Text>
          </VStack>
          <Input
            sx={{ display: 'none' }}
            type='file'
            accept={format}
            onChange={changeFile}
          />
        </FormLabel>
        <Spacer />
        {mode === 'edit' &&
          <>
          <Icon
            as={FiX}
            w={5}
            h={5}
            color='gray.300'
            onClick={() => handleChangeMode('preview')}
          />
          <Icon
            as={FiCheck}
            ml={1}
            mr={5}
            w={5}
            h={5}
            color='green.500'
            onClick={handleSubmit}
          />
          </>
        }
      </HStack>
    }
    </>
  );
};
