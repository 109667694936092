import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Button,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { InlineEditor } from 'views/components/inline_editor';
import { Index as Response } from 'interfaces/api/my/index';

const passwordValidationSchemas = yup.object({
  currentPassword: yup
    .string()
    .required('現在のパスワードを入力してください')
    .min(8, '現在のパスワードを正しく入力してください')
    .max(20, '現在のパスワードを正しく入力してください'),
  password: yup
    .string()
    .required('新しいパスワードを入力してください')
    .min(8, '新しいパスワードを正しく入力してください')
    .max(20, '新しいパスワードを正しく入力してください'),
  passwordConfirm: yup
    .string()
    .required('確認用パスワードを入力してください')
    .min(8, 'パスワードを正しく入力してください')
    .max(20, 'パスワードを正しく入力してください')
    .oneOf([yup.ref('password')], 'パスワードが一致しません')
});

export const Index = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { sendToast } = useToast();

  const passwordForm = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirm: ''
    },
    validationSchema: passwordValidationSchemas,
    onSubmit: (values: any) => {
      const data = {
        currentPassword: values.currentPassword,
        password: values.password
      };


      Fetch.post('my', data).then(res => {
        if (res?.error) {
          sendToast({title: 'パスワード変更に失敗しました', description: '', status: 'error'});
          return false;
        }

        sendToast({title: 'パスワードを変更しました', description: '', status: 'success'});
        navigate('/my');
      });
    },
  });

  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, []);

  return (
    <Layout
      currentNavi='my'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <Heading size='md'>{response?.my?.name ? response.my.name : 'マイ・プロフィール'}</Heading>
            <Divider mt={3} />
          </CardHeader>
          <CardBody>
            {response &&
              <Tabs isFitted variant='enclosed'>
                <TabList>
                  <Tab>基本情報</Tab>
                  <Tab>セキュリティ</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <InlineEditor
                      name='id'
                      value={response.my.id}
                      title='ID'
                      isReadOnly={true}
                    />

                    <InlineEditor
                      name='email'
                      value={response.my.email}
                      title='メールアドレス'
                      isReadOnly={true}
                      type='email'
                    />

                    <InlineEditor
                      name='name'
                      value={response?.my?.name}
                      title='私の名前'
                      isRequired={true}
                      url={`my`}
                    />

                    <InlineEditor
                      name='avatar'
                      value={response.my.avatar}
                      title={response?.my?.name}
                      type='avatar'
                      url={`my`}
                    />

                  </TabPanel>
                  <TabPanel>
                    <form onSubmit={passwordForm.handleSubmit} noValidate>
                      <FormControl
                        isRequired
                        py={5}
                        isInvalid={passwordForm.touched.currentPassword && Boolean(passwordForm.errors.currentPassword)}
                      >
                        <FormLabel htmlFor='password'>現在のパスワード</FormLabel>
                        <Input
                          id='currentPassword'
                          name='currentPassword'
                          type='password'
                          size='lg'
                          placeholder='現在のパスワード'
                          onChange={passwordForm.handleChange}
                          value={passwordForm.values.currentPassword}
                          maxLength={20}
                        />
                        <FormErrorMessage>{String(passwordForm.errors.currentPassword)}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isRequired
                        py={5}
                        isInvalid={passwordForm.touched.password && Boolean(passwordForm.errors.password)}
                      >
                        <FormLabel htmlFor='password'>新しいパスワード</FormLabel>
                        <Input
                          id='password'
                          name='password'
                          type='password'
                          size='lg'
                          placeholder='新しいパスワード'
                          onChange={passwordForm.handleChange}
                          value={passwordForm.values.password}
                          maxLength={20}
                        />
                        <FormErrorMessage>{String(passwordForm.errors.password)}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isRequired
                        py={5}
                        isInvalid={passwordForm.touched.passwordConfirm && Boolean(passwordForm.errors.passwordConfirm)}
                      >
                        <FormLabel htmlFor='passwordConfirm'>確認用パスワード</FormLabel>
                        <Input
                          id='passwordConfirm'
                          name='passwordConfirm'
                          type='password'
                          size='lg'
                          placeholder='確認用パスワード'
                          onChange={passwordForm.handleChange}
                          value={passwordForm.values.passwordConfirm}
                          maxLength={20}
                        />
                        <FormErrorMessage>{String(passwordForm.errors.passwordConfirm)}</FormErrorMessage>
                      </FormControl>

                      <Button
                        colorScheme='base'
                        type='submit'
                        w='full'
                        my={5}
                      >
                        更新する
                      </Button>
                    </form>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
