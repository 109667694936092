import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  VStack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Card,
  CardHeader,
  CardBody,
  Textarea,
  CardFooter,
  Divider,
  SimpleGrid,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/my/index';

const validationSchemas = yup.object({
  title: yup
    .string()
    .required('タイトルを入力してください'),
  content: yup
    .string()
    .required('内容を入力してください')
});

export const Create = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isConfirm, setIsConfirm] = React.useState(false);
  const { sendToast } = useToast();

  const form = useFormik({
    initialValues: {
      title: '',
      content: ''
    },
    validationSchema: validationSchemas,
    onSubmit: (values: any) => {
      if (!isConfirm) {
        setIsConfirm(true);
        return;
      }

      const check = {
        title: values.title,
        content: values.content,
      };

      Fetch.post('checks', check).then(res => {
        if (res?.error) {
          sendToast({title: '作成失敗しました', description: '', status: 'error'});
          return false;
        }

        navigate('/checks');
        return false;
      });
    },
  });

  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, []);

  return (
    <Layout
      currentNavi='checks'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >

        <Card
          w='full'
          h='full'
        >
          <form onSubmit={form.handleSubmit} noValidate>
            <CardHeader>
              <Heading size='md'>チェックシート項目新規作成</Heading>
              <Divider mt={3} />
            </CardHeader>
            <CardBody>
              <FormControl
                isRequired
                pb={3}
                isInvalid={form.touched.title && Boolean(form.errors.title)}
              >
                <FormLabel htmlFor='title'>タイトル</FormLabel>
                <Input
                  id='title'
                  name='title'
                  size='lg'
                  placeholder='タイトル'
                  isRequired
                  onChange={form.handleChange}
                  value={form.values.title}
                  maxLength={50}
                  isReadOnly={isConfirm}
                />
                <FormErrorMessage>{String(form.errors.title)}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                pb={3}
                isInvalid={form.touched.content && Boolean(form.errors.content)}
              >
                <FormLabel htmlFor='content'>内容</FormLabel>
                <Textarea
                  id='content'
                  name='content'
                  placeholder='内容'
                  isRequired
                  onChange={form.handleChange}
                  value={form.values.content}
                  rows={10}
                  isReadOnly={isConfirm}
                />
                <FormErrorMessage>{String(form.errors.content)}</FormErrorMessage>
              </FormControl>

            </CardBody>
            <CardFooter>
              <SimpleGrid
                columns={2}
                spacing={10}
                w='full'
              >
              {!isConfirm ?
                <>
                  <Button
                    as={Link}
                    to='/checks'
                  >
                    キャンセル
                  </Button>
                  <Button
                    colorScheme='blue'
                    type='submit'
                  >
                    確認へ
                  </Button>
                </>
              :
                <>
                  <Button
                    type='button'
                    onClick={() => setIsConfirm(false)}
                  >
                    キャンセル
                  </Button>
                  <Button
                    colorScheme='base'
                    type='submit'
                  >
                    作成する
                  </Button>
                </>
              }
              </SimpleGrid>
            </CardFooter>
          </form>
        </Card>
      </VStack>
    </Layout>
  );
};
