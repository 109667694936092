import * as React from 'react';
import {
  VStack,
  Heading,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react';
import { Chart } from "react-google-charts";
import NumericLabel from 'react-pretty-numbers';

import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { Dashboard as Response } from 'interfaces/api/dashboard';

export const data = [
  ["日別", "決済金額"],
  ["02/01", 1000],
  ["02/02", 1170],
  ["02/03", 660],
  ["02/04", 1030],
  ["02/05", 1030],
  ["02/06", 1030],
  ["02/07", 1030],
  ["02/08", 1030],
  ["02/09", 1030],
  ["02/10", 1030],
  ["02/11", 1030],
  ["02/12", 1030],
];

export const options = {
  chart: {
    title: '日別決済金額推移(クレカのみ)',
    subtitle: '直近1ヶ月',
  },
};

export const Index = () => {
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [payments, setPayments] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    Fetch.get(`dashboard`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      let _payments: any[][] = [
        ['日別', '決済金額']
      ];

      console.log(_payments);

      for (const [key, value] of Object.entries(res.payments)) {
        let payment = [key, value];
        _payments.push(payment);
      }

      setPayments(_payments);

      setResponse(res);
    });
  }, []);

  return (
    <Layout
      currentNavi='dashboard'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>ダッシュボード</Heading>
            </HStack>
          </CardHeader>
          <CardBody>
            <StatGroup
              mb={8}
            >
              <Stat>
                <StatLabel>会員数</StatLabel>
                <StatNumber><NumericLabel>{response?.count?.members}</NumericLabel></StatNumber>
              </Stat>

              <Stat>
                <StatLabel>スタッフ数</StatLabel>
                <StatNumber><NumericLabel>{response?.count?.staffs}</NumericLabel></StatNumber>
              </Stat>
            </StatGroup>

            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={payments}
              options={options}
            />

          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
