import * as React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
} from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  FiPlus,
  FiExternalLink,
  FiShare,
  FiMoreVertical,
  FiTrash2,
} from 'react-icons/fi';
import moment from 'moment';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/seminars/index';
import { Seminar } from 'interfaces/models/seminar';

export const Index = () => {
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);
  const [seminars, setSeminars] = React.useState<undefined | Seminar[]>(undefined);
  const [deleteItems, setDeleteItems] = React.useState<Array<{[key: string]: string}>>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOnDragEnd = (result: any) => {
    if (!seminars) return;
    if (!result.destination) return;
    const updatedItems = [...seminars];

    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setSeminars(updatedItems);

    let request: Array<string> = [];
    updatedItems.forEach((reference, _) => {
      request.push(reference.id);
    });

    Fetch.post('seminars/priorities', request).then(res => {
      if (res?.error) {
        return false;
      }
    });
  };

  const handleDeleteItem = (e: any, id: string, name: string) => {
    if (e.target.checked) {
      setDeleteItems([...deleteItems, {id: id, name: name}]);
    } else {
      setDeleteItems(deleteItems.filter(item => item.id !== id));
    }
  };

  const handleDeleteItems = () => {
    if (deleteItems.length === 0) return;

    let request: Array<string> = [];
    deleteItems.forEach((item, _) => {
      request.push(item.id);
    });

    Fetch.post('seminars/delete', request).then(res => {
      if (res?.error) {
        sendToast({title: '削除失敗しました', description: '', status: 'error'});
        return false;
      }

      setSeminars(seminars?.filter(seminar => !request.includes(seminar.id)));
      setDeleteItems([]);
      setIsOpen(false);
    });
  };

  React.useEffect(() => {
    (async() => {
      await Fetch.get('seminars').then(res => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
        setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);

        if (res?.seminars.length > 0) {
          setSeminars(res.seminars);
        }
      });
    })()
  }, []);

  return (
    <>
    <Layout
      currentNavi='seminars'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>セミナー一覧</Heading>
              <Spacer />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='base'
                aria-label='plus'
                fontSize={20}
                icon={<FiPlus />}
                as={RouterLink}
                to='/seminars/create'
              />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='red'
                aria-label='plus'
                fontSize={20}
                icon={<FiTrash2 />}
                isDisabled={deleteItems.length === 0}
                onClick={() => setIsOpen(true)}
              />
            </HStack>
          </CardHeader>
          <CardBody>
            {(seminars && seminars.length > 0) &&
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <TableContainer>
                      <Table variant='striped'>
                        <TableCaption>セミナー一覧</TableCaption>
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>#</Th>
                            <Th>タイトル</Th>
                            <Th>更新日時</Th>
                          </Tr>
                        </Thead>
                        <Tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {seminars.map((seminar: Seminar, index: number) => (
                            <Draggable key={seminar.id} draggableId={seminar.id} index={index}>
                              {(provided) => (
                                <Tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                >
                                  <Td
                                    maxW={1}
                                    cursor={'pointer'}
                                    {...provided.dragHandleProps}

                                  >
                                    <FiMoreVertical
                                    />
                                  </Td>
                                  <Td>
                                    <HStack>
                                      <>
                                      <Checkbox
                                        mr={2}
                                        colorScheme='red'
                                        size='lg'
                                        onChange={(e) => handleDeleteItem(e, seminar.id, seminar.title)}
                                      />
                                      <Icon
                                        as={FiShare}
                                        color='green.700'
                                        w={5}
                                        h={5}
                                        onClick={() => navigate(`/seminars/${seminar.id}`)}
                                      />
                                      {seminar.id}
                                      </>
                                    </HStack>
                                  </Td>
                                  <Td>
                                    <HStack>
                                      <>
                                      <Icon
                                        as={FiExternalLink}
                                        color='green.700'
                                        w={5}
                                        h={5}
                                        onClick={() => window.open(seminar.url, '_blank')}
                                      />
                                      {seminar.title}
                                      </>
                                    </HStack>
                                  </Td>
                                  <Td>{seminar.updateAt ? moment.unix(seminar.updateAt).format('YYYY/MM/DD HH:mm') : '-'}</Td>
                                </Tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  )}
                </Droppable>
              </DragDropContext>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(true)}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>データを削除しますか？</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant='striped'>
            <Tbody>
              {deleteItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{item.name}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => setIsOpen(false)}>
            キャンセル
          </Button>
          <Button
            colorScheme='red'
            onClick={handleDeleteItems}
          >削除する</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};
