import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  VStack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Card,
  CardHeader,
  CardBody,
  Icon,
  CardFooter,
  Divider,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  FiFileText,
} from 'react-icons/fi';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/my/index';


export const Create = () => {
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [previewFile, setPreviewFile] = React.useState({ filename: '', raw: '' });
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!previewFile || !previewFile.filename) {
      setErrorMessage('ファイルをアップロードしてください');
      return false;
    }

    let download = new FormData();
    download.append('file', previewFile.raw);

    Fetch.post('downloads', download).then(res => {
      if (res?.error) {
        sendToast({title: '作成失敗しました', description: '', status: 'error'});
        return false;
      }

      navigate('/downloads');
      return false;
    });
  };

  const changeFile = (e: any) => {
    e.preventDefault();

    if (e.target.files.length) {
      setPreviewFile({
        filename: e.target.files[0].name,
        raw: e.target.files[0]
      });
    }
  };

  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, []);

  return (
    <Layout
      currentNavi='downloads'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >

        <Card
          w='full'
          h='full'
        >
          <form onSubmit={handleSubmit} noValidate>
            <CardHeader>
              <Heading size='md'>ダウンロード新規作成</Heading>
              <Divider mt={3} />
            </CardHeader>
            <CardBody>

              <FormLabel>
                <VStack
                  w='full'
                  h={150}
                  spacing={5}
                  p={5}
                  borderWidth={1}
                  borderColor='base.500'
                >
                  {previewFile.filename ?
                    <Text fontSize='6xl'>{previewFile.filename}</Text>
                  :
                    <Icon
                      as={FiFileText}
                      w={20}
                      h={20}
                    />
                  }
                  <Text>ここをクリックしてファイルをアップロードしてください</Text>
                </VStack>
                <Input
                  sx={{ display: 'none' }}
                  type='file'
                  accept='application/pdf'
                  onChange={changeFile}
                />
                <Text color='red' mt={3}>{errorMessage}</Text>
              </FormLabel>
            </CardBody>
            <CardFooter>
              <SimpleGrid
                columns={2}
                spacing={10}
                w='full'
              >
                <Button
                  as={Link}
                  to='/downloads'
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='base'
                  type='submit'
                >
                  作成する
                </Button>
              </SimpleGrid>
            </CardFooter>
          </form>
        </Card>
      </VStack>
    </Layout>
  );
};
