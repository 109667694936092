import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';

import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/welcomes/index';
import { Welcome } from 'interfaces/models/welcome';

const columns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    maxWidth: '100px',
    sortable: true,
  },
  {
    name: 'タイトル',
    selector: (row: any) => row.title,
    sortable: true,
  },
];

export const Index = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    (async() => {
      await Fetch.get('welcomes').then(res => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
        setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);
      });
    })()
  }, []);


  return (
    <Layout
      currentNavi='welcomes'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>お知らせ一覧</Heading>
            </HStack>
          </CardHeader>
          <CardBody>

            {(response && response.welcomes.length > 0) ?
              <DataTable
                columns={columns}
                data={response?.welcomes}
                onRowClicked={(row: Welcome) => navigate(`/welcomes/${row.id}`)}
                highlightOnHover
                pointerOnHover
              />
            :
              <>データがありません</>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
